
import {onActivated, ref} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {ElMessage} from 'element-plus';
import {deleteDraft, getDraftList} from "@/api/draft";

export default {
  setup(){

    const store = useStore();

    const router = useRouter();

    const formSize = ref('large');

    onActivated(() => {
      listDraft();
    });

    //适配移动端
    if(store.state.app.isMobile){
      formSize.value = 'mini';
    }else{
      formSize.value = 'large';
    }

    const table:any = ref({
      page: 1,        // 当前在第几页
      pageSize: 30,   // 一页显示多少
      total: 0,       // 数据总量
      loading: false,  // 加载中
      data: [],       // 表格数据
      isMobile: false,// 表格是否移动端
    });

    table.value.isMobile = store.state.app.isMobile;

    const listDraft = () => {
      table.value.loading = true,
          getDraftList({
            page: table.value.page,
            pageSize: table.value.pageSize,
          }).then((res: any) => {
            table.value.data = res.data.list;
            table.value.total = res.data.total;
            table.value.loading = false;
          });
    }

    const handleSizeChange = (pageSize: number) => {
      table.value.pageSize = pageSize;
      listDraft();
    }

    const handleCurrentChange = (page: number) => {
      table.value.page = page;
      listDraft();
    }

    const handleDelete = (id) => {
      deleteDraft(id).then((res) => {
        if(res.data) {
          ElMessage.success("删除成功!");
          listDraft();
        }
      });
    }

    const handleEditClick = (id) => {
      router.push('/draft/edit/' + id);
    }

    return {
      table,
      handleSizeChange,
      handleCurrentChange,
      formSize,
      handleDelete,
      handleEditClick,
    };
  }
}
